<template>
  <div class="addvote">
    <!-- 步骤条 -->
    <div class="steps">
      <Steps :current="current">
        <Step title="基本内容"></Step>
        <Step title="规则配置"></Step>
        <Step title="高级设置"></Step>
      </Steps>
    </div>
    <!-- 主体内容 -->
    <div class="container">
      <!-- 左侧菜单 -->
      <Row class="left-list">
        <Menu theme="light" :active-name="current" @on-select="selectMenu">
          <MenuItem :name="item.id" v-for="item in menuList" :key="item.id">{{
            item.name
          }}</MenuItem>
        </Menu>
      </Row>

      <!-- 右侧表单 -->
      <div class="right-content">
        <keep-alive>
          <component
            :is="componentId"
            :formValidate="formValidate"
            :ruleValidate="ruleValidate"
            :toggle1="toggle1"
            :textObj="textObj"
            :toggle-fn2="toggleFn2"
            @nextStep="nextStep"
            @save="save"
            :ref="componentId"
          ></component>
        </keep-alive>
      </div>
    </div>
     <Spin fix v-if="spinShow"></Spin>
  </div>
</template>

<script>
import baseInfo from '@/views/answer/childrens/changeBaseInfo'
import highSetting from "@/views/answer/childrens/changeHighSetting";
import ruleConfig from "@/views/answer/childrens/changeRuleConfig";

export default {
  props: ['infoId','menuCodeObj'],
  data() {
    return {
       //富文本预览
      toggle1: false, //
      textObj: {
        spinShow: false,
        signInUrl: '', //预览二维码url
      },
       mobileStatus: false,
      TVStatus: false,
  //保存时表单验证
      validateArr: [
        {
          name: "title",
          value: "信息名称不能为空！",
        },
        {
          name: "source",
          value: "信息来源不能为空！",
        },
         {
          name: "subtype",
          value: "信息类型不能为空！",
        },
        {
          name: "startTime",
          value: "请设置答题的开始日期",
        },
        {
          name: "subStartTime",
          value: "请设置答题的开始时间",
        },
        {
          name: "endTime",
          value: "请设置答题的结束日期",
        },
        {
          name: "subEndTime",
          value: "请设置答题的结束时间",
        },
        {
          name:'terminal',
          value:'请选择发布终端'
        },
        {
          name: 'questionBankId',
          value: '请选择题库类型',
        },
         {
          name: 'effectiveTime',
          value: '请设置有效领奖的开始时间',
        },
        {
          name: 'expirationTime',
          value: '请设置有效领奖的结束时间',
        },
         {
          name: 'valiStartTime',
          value: '请设置每天的开始时间',
        },
        {
          name: 'valiEndTime',
          value: '请设置每天的结束时间',
        },
      ],
      spinShow: false,
      //当前右侧表单数据
      formValidate: {
        title: "", //标题
        source: "", //信息来源
        subtype: "", //信息类型
        appointmentPhone: "", //联系电话（预约号码）
        startTime: "", //开始时间
        subStartTime: "",
        endTime: "", //结束时间
        subEndTime: "",
        provinceCode: 44, //省编码
        cityCode: "", //城市编码
        areaCode: "", //区县编码
        streetCode: "", //街道编码
        projectCodeList: "", //社区编码
        city: "", //修改时地址
        content: "", //富文本
        terminal:[],
        photo: "", //封面图片上传路径
        settingImgPath: "", //高级设置背景图片

        voteTypeList: [], //投票方式列表

        //规则配置
        questionBankId: "", // 题库类型
        userVerified: "0", //实名认证 0 不需要 1 需要
        period: "", //周期设置 全程0 每天 24 小时 0-24 ,
        answerNumber: "", //答题局数 ,
        roundQuestionNumber: 10, //每局题目数
        questionLimit: 10, //每题限时 s
        participantsNumber: 10, //参与人数
        winnersNumber: 10, //中奖人数
        awardMethod: "1", //评奖方式
        effectiveTime: "", //有效领奖开始时间 ,
        expirationTime: "", //有效领奖截止时间

        valiStartTime: "", //每天答题起始时间 格式00:00:00 ,
        valiEndTime: "", // 每天答题截止时间 格式00:00:00 ,
        whetherComment: "2", //是否开启评论 2 否 1 是 ,
        recommend: '0', // 是否推荐 0 否 1是
        pushChannel:false,

        modifier: parent.vue.loginInfo.userinfo.nickName, //修改人
        staffId: parent.vue.loginInfo.userinfo.id,
        status: 1,
        creatorId: parent.vue.loginInfo.userinfo.id,
      },
      ruleValidate: {
        title: [
          { required: true, message: "请输入信息名称", trigger: "blur" },
          { max: 50, message: "字数不能超过50字", trigger: "change" },
        ],
        source: [
          { required: true, message: "请输入信息来源", trigger: "blur" },
          { max: 30, message: "字数不能超过30字", trigger: "change" },
        ],
        subtype: [
          { required: true, message: "请选择信息类型", trigger: "blur" },
        ],
        startTime: [
          {
             type: 'array',
            required: true,
            type: "date",
            message: "请设置答题的开始日期",
            trigger: "change",
          },
        ],
        subStartTime: [
          {
            required: true,
            type: "string",
            message: "请设置答题的开始时间",
            trigger: "change",
          },
        ],
        endTime: [
          {
             type: 'array',
            required: true,
            type: "date",
            message: "请设置答题的结束日期",
            trigger: "change",
          },
        ],
        subEndTime: [
          {
            required: true,
            type: "string",
            message: "请设置答题的结束时间",
            trigger: "change",
          },
        ],

        openDiscuss: [
          {
            required: true,
            message: "请选择开启或者关闭评论",
            trigger: "blur",
          },
        ],
        terminal: [
          {
            required: true,
            message: "请选择发布终端",
            trigger: "blur",
          },
        ],
        questionBankId: [
           {
            required: true,
            message: "请选择题库类型",
            trigger: "change",
          },
        ],
        roundQuestionNumber: [
           {
            type: "number",
            message: "请设置有效领奖的开始时间",
            trigger: "change",
          },
        ],
        effectiveTime: [
          {
             type: 'array',
            required: true,
            type: "date",
            message: "请设置有效领奖的开始时间",
            trigger: "change",
          },
        ],
        expirationTime: [
          {
            type: 'array',
            required: true,
            type: "date",
            message: "请设置有效领奖的结束时间",
            trigger: "change",
          },
        ],
        valiStartTime: [
          {
            required: true,
            type: "string",
            message: "请设置每天的开始时间",
            trigger: "change",
          },
        ],
        valiEndTime: [
          {
            required: true,
            type: "string",
            message: "请设置每天的结束时间",
            trigger: "change",
          },
        ],
        recommend: [
          {
            required: true,
            message: "此项为必填",
            trigger: "change",
          },
        ],
      },
      //当前右侧组件ID
      componentId: "baseInfo",
      //步进器进度
      current: 0,
      //左侧菜单
      menuList: [
        {
          id: 0,
          name: "基本内容",
        },
        {
          id: 1,
          name: "规则配置",
        },
        {
          id: 2,
          name: "高级设置",
        },
      ],
    };
  },
   watch: {
    current(newVal) {
      this.$emit('comSelectMenu',newVal)
    }
  },
  created() {
    this.spinShow = true;
    //获取数据字典信息
    Promise.all([
      this.getDictionaries("VOTE_TYPE"),
      this.getDictionaries("VOTE_CYCLE"),
      this.getDictionaries("VOTE_ITEM_SEQ"),
      this.getDictionaries("VOTE_ITEM_NAME"),
      this.getDictionaries("VOTE_BUTTON_NAME"),
      this.getDictionaries("VOTE_BUTTON_UNIT"),
      this.getDictionaries("VOTE_DISCUSS"),
    ]).then((res) => {
      this.formValidate.voteTypeList = res[0].dataList;
      this.formValidate.voteCycleList = res[1].dataList;
      this.formValidate.voteItemSeqList = res[2].dataList;
      this.formValidate.voteItemNameList = res[3].dataList;
      this.formValidate.buttonNameList = res[4].dataList;
      this.formValidate.buttonUnitList = res[5].dataList;
      this.formValidate.openDiscussList = res[6].dataList;
    })
     this.$get("/voteapi/api/pc/affiche/getAfficheInfoManager", {
        infoId: this.infoId,
      }).then((res) => {
        if (res.code == 200) {
          this.formValidate.city = res.dataList[0];
        }
      });
    //获取信息详情
    this.$get("/voteapi/api/pc/affiche/emp/queryById", {
      operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      orgCode: parent.vue.loginInfo.userinfo.orgCode,
      oemCode: parent.vue.oemInfo.oemCode,
      businessType: this.menuCodeObj.businessType,
      functionType: this.menuCodeObj.functionType,
      infoId: this.infoId,
    }).then((res) => {
      if (res.code == 200) {
         let endTime = new Date(res.data.endTime);
        let startTime = new Date(res.data.startTime);
          res.data.startTime = startTime;
          res.data.endTime = endTime;
        for (let k in res.data) {
          if (this.formValidate.hasOwnProperty(k)) {
            this.formValidate[k] = res.data[k];
          }
          if(k == 'answer') {
            for(let j in res.data.answer) {
              if(this.formValidate.hasOwnProperty(j)) {
                if(j == 'endTime' || j =="startTime") continue;
                this.formValidate[j] = res.data.answer[j];
              }
               if(j == 'ruleVo') {
                 for(let i in res.data.answer.ruleVo) {
                   if(this.formValidate.hasOwnProperty(i)) {
                     this.formValidate[i] = res.data.answer.ruleVo[i];
                   }
                 }
               }
            }
          }
        }
        if(this.formValidate.effectiveTime) {
            this.formValidate.effectiveTime = new Date(this.formValidate.effectiveTime);
        }
        if(this.formValidate.expirationTime) {
            this.formValidate.expirationTime = new Date(this.formValidate.expirationTime);
        }
        if(this.formValidate.terminal && this.formValidate.terminal != ''){
          this.formValidate.terminal = this.formValidate.terminal.split(',')
        }
        this.formValidate.subStartTime = new Date(this.formValidate.startTime);
        this.formValidate.subEndTime = new Date(this.formValidate.endTime);
        this.formValidate.pushChannel=this.formValidate.pushChannel==='4'?true:false
        this.spinShow = false;
      }else {
        
        this.$Message.error({
          background: true,
          content: '获取答题信息失败！'
        })
        this.spinShow = false;
      }
    }).catch(err => {
      this.$Message.error({
        background: true,
        content: '获取答题信息失败！'
      })
      this.spinShow = false;
    })
  },
  methods: {
    getDictionaries(type) {  //获取数据字典数据
       return this.$get('/voteapi/api/common/sys/findDictByDictType',{
        dictType: type
      })
    },
    //删除选项管理中的选项
    delOption(index) {
      this.formValidate.voteItemRos.splice(index, 1);
    },
    // 对时间数据进行转化
    toggleTime(time, subtime) {
      if (time && subtime) {
        let startTime = new Date(time);
        let startYear = startTime.getFullYear();
        let startMonth =
          startTime.getMonth() + 1 < 10
            ? "0" + (startTime.getMonth() + 1)
            : startTime.getMonth() + 1;
        let startDay =
          startTime.getDate() < 10
            ? "0" + startTime.getDate()
            : startTime.getDate();
        return startYear + "-" + startMonth + "-" + startDay + " " + subtime;
      }
    },
    //保存时验证表单必填
    validate() {
      for (let k in this.validateArr) {
        if (
          k == this.validateArr.length - 1 &&
          this.formValidate[this.validateArr[k].name] !== ""
        ) {
          return true;
        }
        if (this.formValidate[this.validateArr[k].name] == "") {
          this.$Message["error"]({
            background: true,
            content: this.validateArr[k].value,
          });
          return false;
        }
      }
    },

  toggleFn2() {
      this.toggle1 = false
    },
    contentFn() {   //富文本预览
      var v_Obj = document.getElementById("ueditor").contentWindow; //获取iframe对象
      this.formValidate.content = v_Obj.getContent(); //获取富文本内容
      // console.log(this.formValidate.content, '内容')

      if (!this.formValidate.content) {
        this.$Message.warning({
          background: true,
          content: '暂没内容，点击编辑吧！',
          duration: 2
        });
        return
      }
      this.$Message.loading({
        background: true,
        content: '正在加载，请稍等...'
      });


      let params = {
        content: this.formValidate.content
      }
      this.$post(
          "/datamsg/api/pc/wxqr/createWxAppletQr",
          {
            type: "RICH_TEXT_PREVIEW_PAGE",
            value: JSON.stringify({
              path: "pages/welcome/welcome",
              redirectPath:
                  "/indexPages/previewRichText/index",
              params: {
                ...params,
              }
            })
          },
          {"Content-Type": "application/json"}
      ).then(res => {
        console.log(res, 'res')
        if (res.code == 200 && res.data) {
          this.textObj.signInUrl = res.data.codeUrl;
          this.toggle1 = true
          this.$Message.destroy()
        } else {
          this.$Message.destroy()
          this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      });
    },
    //保存
    save() {
      if(this.formValidate.pushChannel == true){
        this.$Modal.confirm({
          title: "退出提示",
          content: "<p>当前发布包括推送，是否继续发布？</p>",
          onOk: () => {
              this.addPush()
            }
          })
      }else{
          this.addPush()
      }
    },
    // 把内容包裹起来
    addPush(){
         
      if (this.validate()) {
        this.formValidate.startTime = this.toggleTime(
          this.formValidate.startTime,
          this.formValidate.subStartTime
        );
        this.formValidate.endTime = this.toggleTime(
          this.formValidate.endTime,
          this.formValidate.subEndTime
        );

       if(this.componentId == 'baseInfo') {
         var v_Obj=document.getElementById("ueditor").contentWindow;//获取iframe对象
        this.formValidate.content = v_Obj.getContent();//获取富文本内容
      }
      this.formValidate.pushChannel = this.formValidate.pushChannel === false ? '' : '4'
        let data = {
          answer: {
            businessCode: "",
             businessType: this.menuCodeObj.businessType,
            functionType: this.menuCodeObj.functionType,
            endTime: this.formValidate.endTime,
            orgCode: parent.vue.loginInfo.userinfo.orgCode,
            questionBankId: this.formValidate.questionBankId,
            ruleVo: {
              answerNumber: this.formValidate.answerNumber,
              awardMethod: this.formValidate.awardMethod,
              effectiveTime: this.formValidate.effectiveTime,
              expirationTime: this.formValidate.expirationTime,
              participantsNumber: this.formValidate.participantsNumber,
              period: this.formValidate.period, //周期设置
              questionLimit: this.formValidate.questionLimit,
              roundQuestionNumber: this.formValidate.roundQuestionNumber,
              userVerified: this.formValidate.userVerified,
              valiEndTime: this.formValidate.valiEndTime,
              valiStartTime: this.formValidate.valiStartTime,
              whetherComment: this.formValidate.whetherComment,
              winnersNumber: this.formValidate.winnersNumber,
            },
            startTime: this.formValidate.startTime,
            status: '1',
          },
          createAfficheRo: {
            address: "",
            afficheType: "",
            appointmentPhone: this.formValidate.appointmentPhone,
            averagePrice: "",
            businessTime: "",
             businessType: this.menuCodeObj.businessType,
            functionType: this.menuCodeObj.functionType,
            carousel: 0,
            content: this.formValidate.content,
            creatorId: this.formValidate.creatorId,
            endTime: this.formValidate.endTime,
            id: this.infoId,
            introduction: "",
            label: "",
            latitude: "",
            linkUrl: "",
            liveBroadcastRoomNum: "",
            longitude: "",
            operType: "UPDATE",
            orgCode: parent.vue.loginInfo.userinfo.orgCode,
            peopleNum: 0,
            photo: this.formValidate.photo.indexOf('?') != '-1' ? this.formValidate.photo.substring(0,this.formValidate.photo.indexOf('?')) : this.formValidate.photo,
            priority: "",
            reason: "",
            shopType: "",
            source: this.formValidate.source,
            staffId: this.formValidate.staffId,
            startTime: this.formValidate.startTime,
            status: this.formValidate.status,
            subtype: this.formValidate.subtype,
            terminal: this.formValidate.terminal.join(','),
            title: this.formValidate.title,
            recommend: this.formValidate.recommend, //是否推荐
            pushChannel: this.formValidate.pushChannel, //小程序订阅推送
            type: this.menuCodeObj.columnType,
            columnCode: this.menuCodeObj.columnCode
          },
          ruleVo: {
            businessCode: "",
            businessType: this.menuCodeObj.businessType,
            endTime: this.formValidate.endTime,
            orgCode: parent.vue.loginInfo.userinfo.orgCode,
            questionBankId: this.formValidate.questionBankId,
            ruleVo: {
              answerNumber: this.formValidate.answerNumber,
              awardMethod: this.formValidate.awardMethod,
              effectiveTime: this.formValidate.effectiveTime,
              expirationTime: this.formValidate.expirationTime,
              participantsNumber: this.formValidate.participantsNumber,
              period: this.formValidate.period,
              questionLimit: this.formValidate.questionLimit,
              roundQuestionNumber: this.formValidate.roundQuestionNumber,
              userVerified: this.formValidate.userVerified,
              valiEndTime: this.formValidate.valiEndTime,
              valiStartTime: this.formValidate.valiStartTime,
              whetherComment: this.formValidate.whetherComment,
              winnersNumber: this.formValidate.winnersNumber,
            },
            startTime: this.formValidate.startTime,
            status: '1',
          },
        };
        this.$post("/voteapi/api/pc/affiche/emp/updateAfficheQuestion",data,{"Content-Type": "application/json"}).then((res) => {
          if (res.code == 200) {
            this.$Message["success"]({
              background: true,
              content: "修改成功",
            });
             this.$emit('pageChange',{
              page: 1,
              pageSize: 20
            });
            //重新获取信息状态的数量
            this.$emit("messageStatusCount");
            this.$emit("addChangeModalFn",false)
          } else {
            this.$Message["error"]({
              background: true,
              content: res.desc,
            });
          }
        });
      }
    },
    //步进器上下一步
    nextStep(value) {
      this.selectMenu(this.current + value);
    },
    selectMenu(name) {
       if(this.componentId == 'baseInfo') {
         var v_Obj=document.getElementById("ueditor").contentWindow;//获取iframe对象
        this.formValidate.content = v_Obj.getContent();//获取富文本内容
      }
      this.current = name;
      switch (name) {
        case 0:
          this.componentId = "baseInfo";
          break;
        case 1:
          this.componentId = "ruleConfig";
          break;
        default:
          this.componentId = "highSetting";
      }
    },
  },
  components: {
    baseInfo,
    highSetting,
    ruleConfig,
  },
};
</script>
<style scoped lang='less'>
.addvote {
  background: #fcfcfc;
  text-align: left;

  .steps {
    height: 80px;
    margin: 20px 50px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .ivu-steps {
      width: 80%;
    }
    .ivu-steps-status-process,
    .ivu-steps-status-finish {
      /deep/.ivu-steps-head-inner {
        border-color: #12b26d;
        background-color: #12b26d;
      }
    }
    /deep/.ivu-steps-icon {
      color: #fff;
    }
    /deep/.ivu-steps-item.ivu-steps-status-finish .ivu-steps-tail > i:after {
      background: #12b26d;
    }
    /deep/.ivu-steps-status-error .ivu-steps-icon {
      color: #ed4014;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin: 0 50px;
    min-height: calc(100vh - 222px);
    .ivu-menu-item {
      color: #303133;
      background: #fff;
    }
    .ivu-menu-item:hover {
      color: #409eff;
    }
    .ivu-menu-item-selected.ivu-menu-item-active {
      background: #fff;
      color: #409eff;
    }
    .left-list {
      height: 100%;
      width: 220px;
    }
    /deep/.ivu-menu {
      text-align: center;
      width: 220px !important;
      background: #fcfcfc;
      li {
        background: #fcfcfc;
      }
      li,
      .ivu-menu-item-selected:hover,
      li:hover {
        color: #12b26d;
      }
      .ivu-menu-item-selected {
        color: #12b26d;
      }
    }
    .ivu-menu-vertical.ivu-menu-light:after {
      width: 2px;
    }
    .ivu-menu-item-active:not(.ivu-menu-submenu):after {
      background: #12b26d;
    }
    .right-content {
      flex: 1;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      background: #fff;
      height: calc(100vh - 222px);
      padding: 20px;
    }
  }
}
</style>
